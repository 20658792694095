import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { StAppBarWrapper } from 'components/DTP/HeaderNavigation/HeaderNavigation.styles';
import isEmpty from 'lodash/isEmpty';
import React, { useRef } from 'react';
import { LayoutServiceData } from '@sitecore-jss/sitecore-jss-nextjs';

interface LayoutProps {
  layoutData: LayoutServiceData;
}

const SMALayout = ({ layoutData }: LayoutProps) => {
  const theme = useTheme();
  const { route, context } = layoutData.sitecore;
  const isFooterExist = !isEmpty(route?.placeholders['jss-footer']);
  const pageContainerRef = useRef<HTMLElement>(null);

  return (
    <>
      {route && (
        <div>
          <StAppBarWrapper>
            <AppBar
              position={'fixed'}
              sx={{
                bgcolor: 'appBarColor.main',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                [theme.breakpoints.up('md')]: {
                  position:
                    isEditorActive() || context?.pageState === 'preview' ? 'static' : 'fixed',
                },
                boxShadow: 'unset',
                borderBottom: '1px solid #d9d9d9',
              }}
            >
              <Placeholder name="jss-header" rendering={route} />
            </AppBar>
            <Toolbar />
          </StAppBarWrapper>
        </div>
      )}

      <main className="container" ref={pageContainerRef}>
        {route && (
          <Placeholder
            name="jss-main"
            rendering={route}
            pageContainerRef={pageContainerRef}
            isFooterExist={isFooterExist}
          />
        )}
      </main>
      {isFooterExist && (
        <footer id="footer">{route && <Placeholder name="jss-footer" rendering={route} />}</footer>
      )}
    </>
  );
};

export default SMALayout;
